<template>
  <div>
    <statistic-date-picker
      ref="datepicker"
      :loading="loading"
      @changed="dateChanged"
    >
      <div :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }">
        <v-btn
          @click="exportMessages()"
          :loading="loadingExport"
          :disabled="loading"
          class="mr-2"
        >
          <v-icon small left>mdi-file-excel-box-outline</v-icon>
          {{ $t("btn.export") }}
        </v-btn>
      </div>
      <div :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }">
        <v-btn @click="getFilter()" :disabled="loading" class="mr-2">
          <v-icon small left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
      </div>
      <div :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }">
        <v-btn @click="getStatistics()" :disabled="loading" class="mr-2">
          <v-icon small left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
      </div>
    </statistic-date-picker>
    <statistic-summary
      :loading="loading"
      :summary="summary"
    ></statistic-summary>
    <statistic-channel
      :channel-doughnut-chart="channelDoughnutChart"
      :channel-line-chart="channelLineChart"
      :loading="loading"
    ></statistic-channel>
    <statistic-service
      :loading="loading"
      :service-doughnut-chart="serviceDoughnutChart"
      :service-line-chart="serviceLineChart"
    ></statistic-service>
    <statistic-filter
      :dialog="dialog.filter"
      :configuration="filterStatisticOptions"
      @filter="filterStatisticApply"
    ></statistic-filter>
  </div>
</template>

<script>
import { CHANNEL_COLOR, DEFAULT_CHART_FONT } from "./constants";
import { mapActions } from "vuex";
import StatisticDatePicker from "./datepicker";
import StatisticSummary from "./summary.vue";
import StatisticChannel from "./channel.vue";
import StatisticService from "./service.vue";
import StatisticFilter from "./../commons/filter.vue";
import FilterMixin from "./../../mixins/filter";

import { mapGetters } from "vuex";

export default {
  mixins: [FilterMixin],
  mounted() {
    this.dates = this.$refs.datepicker.getDates();
    this.setDatesRange();
    this.getStatistics();
  },
  async created() {
    await this.getServicesItems();
    this.setfilterStatisticOptions();
  },
  data: () => ({
    dates: [],
    datesRange: [],
    loading: false,
    loadingExport: false,
    summary: {
      messages_sent: 0,
      messages_received: 0,
      message_sent_price: 0,
    },
    channelDoughnutChart: {
      data: {},
      options: {},
    },
    channelLineChart: {
      data: {},
      options: {},
    },
    serviceDoughnutChart: {
      data: {},
      options: {},
    },
    serviceLineChart: {
      data: {},
      options: {},
    },
    statistics: {},
    format: "YYYY-MM-DD",
    dialog: {
      filter: {
        display: false,
        title: null,
      },
    },
    filterStatisticOptions: [],
    serviceItems: [],
    filters: [],
  }),
  methods: {
    dateChanged(dates) {
      if (this._.difference(dates, this.dates).length === 0) return;
      this.dates = dates;
    },
    setDatesRange() {
      const start = this.$moment(this.dates[0], this.format);
      const end = this.$moment(this.dates[1], this.format);
      while (start.isSameOrBefore(end)) {
        this.datesRange.push(start.format("YYYY-MM-DD"));
        start.add(1, "day");
      }
    },
    async getStatistics() {
      this.loading = true;
      try {
        const qb = this.$requestBuilder.create();
        // set date filters
        qb.setFilter({
          field: "createdAt",
          operator: "$gte",
          value: this.dates[0] + " 00:00:00",
        })
          .setFilter({
            field: "createdAt",
            operator: "$lte",
            value: this.dates[1] + " 23:59:59",
          })
          .sortBy({ field: "createdAt", order: "DESC" });

        // set other filters
        for (const filter of this.filters) {
          qb.setFilter(filter);
        }

        const response = await this.request({
          url: `messages.statistics?${qb.query()}&sum=cost&group=createdAt&group=direction&group=status&group=conversation.channel.service.name&group=channel&group=conversation.contactInfo.identifier&group=charged&group=author.username&group=type`,
          params: {},
        });
        const data = response.data;
        this.setStatistics(data);
      } catch (error) {
        // empty
      }
      this.loading = false;
    },
    async setStatistics(datas) {
      const summary = {
        messages_sent: 0,
        messages_received: 0,
        message_sent_price: 0,
      };
      const channel_doughnut = {};
      const channel_line = {};
      const channels = {};
      const services = {};
      const service_doughnut = {};
      const service_line = {};

      for (const data of datas) {
        this.addSummaryData(data, summary);
        this.addChannelDoughnutData(data, channel_doughnut);
        this.addChannelLineData(data, channels, channel_line);
        this.addServiceDoughnutData(data, service_doughnut);
        this.addServiceLineData(data, services, service_line);
      }
      // set summary
      this.summary = summary;
      // set chart channel doughnut and line
      this.channelDoughnutChart =
        this.getChannelDoughnutChart(channel_doughnut);
      this.channelLineChart = this.getChannelLineChart(channel_line, channels);

      // set chart service doughnut and line
      this.serviceDoughnutChart =
        this.getServiceDoughnutChart(service_doughnut);
      this.serviceLineChart = this.getServiceLineChart(service_line, services);
    },
    addSummaryData(data, summary) {
      if (data.message_direction_group === "OUT") {
        if (data.message_charged_group) {
          summary.messages_sent += parseInt(data.message_id_count || "0");
          summary.message_sent_price += parseInt(data.cost_sum || "0");
        }
      } else {
        summary.messages_received += parseInt(data.message_id_count || "0");
      }
    },
    addChannelDoughnutData(data, channel_doughnut) {
      if (!channel_doughnut[data.message_type_group]) {
        channel_doughnut[data.message_type_group] = 0;
      }
      channel_doughnut[data.message_type_group] += parseInt(
        data.message_id_count || "0"
      );
    },
    addChannelLineData(data, channels, channel_line) {
      if (!channels[data.message_type_group])
        channels[data.message_type_group] = data.message_type_group;
      if (!channel_line[data.created_at_group])
        channel_line[data.created_at_group] = {};
      if (!channel_line[data.created_at_group][data.message_type_group])
        channel_line[data.created_at_group][data.message_type_group] = 0;

      channel_line[data.created_at_group][data.message_type_group] += parseInt(
        data.message_id_count || "0"
      );
    },
    getChannelDoughnutChart(channel_doughnut) {
      const data = {
        labels: [],
        datasets: [
          {
            label: this.$t("statistics.channels.title"),
            data: [],
            backgroundColor: [],
          },
        ],
      };
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: DEFAULT_CHART_FONT,
      };
      for (const prop in channel_doughnut) {
        data.labels.push(prop);
        data.datasets[0].data.push(channel_doughnut[prop]);
        data.datasets[0].backgroundColor.push(CHANNEL_COLOR[prop]);
      }
      return {
        data: data,
        options: options,
      };
    },
    getChannelLineChart(channel_line, channels) {
      const labels = this.datesRange;
      const datasets = {};

      for (const date of this.datesRange) {
        for (const chan in channels) {
          if (!datasets[chan])
            datasets[chan] = {
              label: chan,
              data: [],
              fill: false,
              borderColor: CHANNEL_COLOR[chan],
              tension: 0.1,
            };
          datasets[chan].data.push(
            !this.$utils.hasProperty(channel_line, `${date}.${chan}`)
              ? 0
              : channel_line[date][chan]
          );
        }
      }

      const data = {
        labels: labels,
        datasets: Object.values(datasets),
      };

      return {
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: DEFAULT_CHART_FONT,
        },
      };
    },
    addServiceDoughnutData(data, service_doughnut) {
      if (!service_doughnut[data.service_name_group]) {
        service_doughnut[data.service_name_group] = 0;
      }
      service_doughnut[data.service_name_group] += parseInt(
        data.message_id_count || "0"
      );
    },
    addServiceLineData(data, services, service_line) {
      if (!services[data.service_name_group])
        services[data.service_name_group] = data.service_name_group;
      if (!service_line[data.created_at_group])
        service_line[data.created_at_group] = {};
      if (!service_line[data.created_at_group][data.service_name_group])
        service_line[data.created_at_group][data.service_name_group] = 0;

      service_line[data.created_at_group][data.service_name_group] += parseInt(
        data.message_id_count || "0"
      );
    },
    getServiceDoughnutChart(service_doughnut) {
      const data = {
        labels: [],
        datasets: [
          {
            label: this.$t("statistics.channels.title"),
            data: [],
            backgroundColor: [],
          },
        ],
      };
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: DEFAULT_CHART_FONT,
      };
      for (const prop in service_doughnut) {
        data.labels.push(prop);
        data.datasets[0].data.push(service_doughnut[prop]);
        data.datasets[0].backgroundColor.push(this.$utils.stringToColor(prop));
      }
      return {
        data: data,
        options: options,
      };
    },
    getServiceLineChart(service_line, services) {
      const labels = this.datesRange;
      const datasets = {};

      for (const date of this.datesRange) {
        for (const service in services) {
          if (!datasets[service])
            datasets[service] = {
              label: service,
              data: [],
              fill: false,
              borderColor: this.$utils.stringToColor(service),
              tension: 0.1,
            };
          datasets[service].data.push(
            !this.$utils.hasProperty(service_line, `${date}.${service}`)
              ? 0
              : service_line[date][service]
          );
        }
      }

      const data = {
        labels: labels,
        datasets: Object.values(datasets),
      };

      return {
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: DEFAULT_CHART_FONT,
        },
      };
    },
    getFilter() {
      this.dialog.filter = {
        display: true,
        title: this.$t("statistics.messages.filter.title"),
      };
    },
    setfilterStatisticOptions() {
      const self = this;
      this.filterStatisticOptions = [
        {
          name: "conversation.contactInfo.identifier",
          type: {
            name: "VTextField",
            operator: "$eq",
            operators: [
              { value: "$eq", text: this.$t("form.fields.operator.eq") },
              {
                value: "$contL",
                text: this.$t("form.fields.operator.contains"),
              },
            ],
            options: {
              label: this.$t("conversations.fields.contact_identifier.title"),
              placeholder: this.$t(
                "conversations.fields.contact_identifier.title"
              ),
              clearable: true,
            },
          },
        },
        {
          name: "conversation.sourceId",
          type: {
            name: "VTextField",
            operator: "$eq",
            operators: [
              { value: "$eq", text: this.$t("form.fields.operator.eq") },
              {
                value: "$contL",
                text: this.$t("form.fields.operator.contains"),
              },
            ],
            options: {
              label: this.$t("conversations.fields.source_id.title"),
              placeholder: this.$t("conversations.fields.source_id.title"),
              clearable: true,
            },
          },
        },
        {
          name: "type",
          type: {
            name: "VSelect",
            operator: "$eq",
            options: {
              items: self.channelTypes,
              label: self.$t("conversations.fields.channel_identifier.title"),
              clearable: true,
            },
          },
        },
        {
          name: "service.id",
          type: {
            name: "VSelect",
            operator: "$eq",
            options: {
              items: this.serviceItems,
              "item-value": "id",
              "item-text": "name",
              label: this.$t("common.fields.serviceId.title"),
              placeholder: this.$t("common.fields.serviceId.placeholder"),
              color: "primary",
            },
          },
        },
        {
          name: "conversation.totalMessageUnread",
          type: {
            name: "VCheckbox",
            parser: (value) => {
              if (value) {
                return {
                  field: "conversation.totalMessageUnread",
                  operator: "$ne",
                  value: 0,
                };
              } else {
                return null;
              }
            },
            options: {
              label: this.$t("conversations.fields.message_unreaded.title"),
              color: "primary",
            },
          },
        },
      ];
    },
    filterStatisticApply(form) {
      this.filters = this.filterApply(form);
      this.getStatistics();
    },
    async getServicesItems() {
      try {
        const response = await this.request({ url: "services.list?limit=999" });
        this.serviceItems = response.data.data;
      } catch (error) {
        // empty
      }
    },
    async exportMessages() {
      this.loadingExport = true;
      try {
        const qb = this.$requestBuilder.create();
        // set date filters
        qb.setFilter({
          field: "createdAt",
          operator: "$gte",
          value: this.dates[0] + " 00:00:00",
        })
          .setFilter({
            field: "createdAt",
            operator: "$lte",
            value: this.dates[1] + " 23:59:59",
          })
          .sortBy({ field: "createdAt", order: "DESC" });

        // set other filters
        for (const filter of this.filters) {
          qb.setFilter(filter);
        }

        const response = await this.request({
          url: `messages.export?${qb.query()}&sort=id,DESC`,
          messages: {
            422: (error) => {
              if (error.code === "E100") {
                this.notify({ message: this.$t("common.noDataToExport") });
              } else if (error.code === "E101") {
                this.notify({
                  message: this.$t("common.exceededSizeDataToExport"),
                });
              } else {
                this.notify({ message: this.$t("error_occured") });
              }
            },
            500: true,
          },
        });
        const data = response.data;
        this.downloadExportFile(data.filename);
      } catch (error) {
        // empty
      }
      this.loadingExport = false;
    },
    downloadExportFile(path) {
      const url = process.env.VUE_APP_BASE_URL + "/" + path;
      this.$utils.downloadUri(
        url,
        `messages-export-${new Date().getTime()}.xlsx`
      );
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  computed: {
    ...mapGetters({ channelTypes: "channel/channelTypes" }),
  },
  components: {
    StatisticDatePicker,
    StatisticSummary,
    StatisticChannel,
    StatisticService,
    StatisticFilter,
  },
};
</script>