<template>
  <div>
    <statistic-message></statistic-message>
  </div>
</template>

<script>
import StatisticMessage from './../../components/statistics/message.vue';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t("statistics.message.title")
    };
  },
  components: {
    StatisticMessage
  }
}
</script>