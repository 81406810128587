export default {
  methods: {
    filterApply(form) {
      const filters = [];
      for (const prop in form) {
        const value = form[prop].value;
        const operator = form[prop].operator;
        const parser = form[prop].parser;
        if (value !== null && typeof value !== 'undefined') {
          let filter;
          if (parser) {
            filter = parser(value, operator);
          } else {
            filter = { field: prop, operator: operator || "$eq", value: value };
          }
          if (filter) {
            filters.push(filter);
          }
        }
      }
      return filters;
    },
  },
};
