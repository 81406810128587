<template>
  <div>
    <v-row>
      <v-col md="4">
        <v-card>
          <v-card-title>
            <span class="text-subtitle-1 font-weight-bold">{{
              $t("statistics.services.title")
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!loading" style="min-height: 120px">
            <div
              v-if="serviceDoughnutChart.data.datasets"
              class="d-flex justify-center"
            >
              <doughnut-chart
                :chart-data="serviceDoughnutChart.data"
                :options="serviceDoughnutChart.options"
                style="max-width: 300px"
              ></doughnut-chart>
            </div>
          </v-card-text>
          <v-card-text v-else style="min-height: 120px">
            <div class="text-center mt-5">
              <v-progress-circular
                indeterminate
                color="secondary"
                width="4"
                size="50"
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="8">
        <v-card>
          <v-card-title>
            <span class="text-subtitle-1 font-weight-bold">{{
              $t("statistics.services.title2")
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!loading" style="min-height: 120px;">
            <div v-if="serviceLineChart.data.datasets">
              <line-chart
                :chart-data="serviceLineChart.data"
                :options="serviceLineChart.options"
                style="height: 400px"
              ></line-chart>
            </div>
          </v-card-text>
          <v-card-text v-else style="min-height: 120px">
            <div class="text-center mt-5">
              <v-progress-circular
                indeterminate
                color="secondary"
                width="4"
                size="50"
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DoughnutChart from "./../commons/charts/doughnut.js";
import LineChart from "./../commons/charts/line.js";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    serviceDoughnutChart: {
      type: Object,
      default: function () {
        return {
          data: {},
          options: {},
        };
      },
    },
    serviceLineChart: {
      type: Object,
      default: function () {
        return {
          data: {},
          options: {},
        };
      },
    },
  },
  data: () => ({}),
  components: {
    DoughnutChart,
    LineChart,
  },
};
</script>