<template>
  <v-dialog v-model="dialog.display" :max-width="dialog.maxWidth || '500'">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ dialog.title }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-3">
        <template v-for="(config, k) in configuration">
          <template v-if="form[config.name]">
            <div :key="k" :class="{ 'd-flex': config.type.operators }">
              <component
                :key="k"
                v-model="form[config.name].value"
                :is="config.type.name"
                v-bind="config.type.options"
                v-on="config.type.events"
              ></component>
              <template v-if="config.type.operators">
                <v-select
                  class="ml-3"
                  :items="config.type.operators"
                  v-model="form[config.name].operator"
                  :label="$i18n.t('form.fields.operator.title')"
                ></v-select>
              </template>
            </div>
          </template>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="reset()" text>{{ $i18n.t("btn.reset") }}</v-btn>
        <v-btn @click="filter()" text color="primary">
          {{ $i18n.t("btn.filter") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
          maxWidth: 500,
          title: "",
        };
      },
    },
    configuration: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    form: {},
  }),
  watch: {
    "dialog.display"(val) {
      if (val && this._.isEmpty(this.form)) this.setForm();
    },
  },
  methods: {
    async setForm() {
      for (const config of this.configuration) {
        this.$set(this.form, [config.name], {
          value: config.type.value || null,
          operator: config.type.operator || null,
          parser: config.type.parser,
        });
      }
    },
    close() {
      this.dialog.display = false;
    },
    filter() {
      this.$emit("filter", this.form);
      this.dialog.display = false;
    },
    reset() {
      for (const prop in this.form) {
        this.form[prop].value = null;
      }
      this.$emit("filter", this.form);
      this.dialog.display = false;
    },
  },
  components: {
    VTextField: () => import("vuetify/lib/components/VTextField"),
    VSelect: () => import("vuetify/lib/components/VSelect"),
    VCheckbox: () => import("vuetify/lib/components/VCheckbox/VCheckbox"),
    VAutocomplete: () => import("vuetify/lib/components/VAutocomplete"),
  },
};
</script>

