<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card color="secondary" class="white--text">
            <v-card-title primary-title>
              <v-row align="center">
                <v-col cols="2" align-self-center>
                  <div class="my-auto">
                    <v-icon left dark :size="hover ? 55 : 50"
                      >mdi-email-receive-outline</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="10">
                  <template v-if="!loading">
                    <div class="text-right">
                      <span class="text-h3">{{ summary.messages_received }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-xs-right">
                      <span class="text-h3">...</span>
                    </div>
                  </template>
                  <div class="text-right text-body-1">
                    {{
                      $t("statistics.messages.received.title")
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              class="my-0"
              color="white"
              height="1"
              :indeterminate="loading"
            ></v-progress-linear>
            <v-divider light></v-divider>
            <v-card-actions>
              <span class="text-body-2">{{
                $t("statistics.messages.received.description")
              }}</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card color="cyan accent-4" class="white--text">
            <v-card-title primary-title>
              <v-row align="center">
                <v-col cols="2" align-self-center>
                  <div class="my-auto">
                    <v-icon left dark :size="hover ? 55 : 50"
                      >mdi-email-send-outline</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="10">
                  <template v-if="!loading">
                    <div class="text-right">
                      <span class="text-h3">{{ summary.messages_sent }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-xs-right">
                      <span class="text-h3">...</span>
                    </div>
                  </template>
                  <div class="text-right text-body-1">
                    {{
                      $t("statistics.messages.sent.title")
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              class="my-0"
              color="white"
              height="1"
              :indeterminate="loading"
            ></v-progress-linear>
            <v-divider light></v-divider>
            <v-card-actions>
              <span class="text-body-2">{{
                $t("statistics.messages.sent.description")
              }}</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card color="indigo accent-4" class="white--text">
            <v-card-title primary-title>
              <v-row align="center">
                <v-col cols="2" align-self-center>
                  <div class="my-auto">
                    <v-icon left dark :size="hover ? 55 : 50"
                      >mdi-credit-card-multiple-outline</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="10">
                  <template v-if="!loading">
                    <div class="text-right">
                      <span class="text-h3">{{ summary.message_sent_price }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-xs-right">
                      <span class="text-h3">...</span>
                    </div>
                  </template>
                  <div class="text-right text-body-1">
                    {{
                      $t("statistics.messages.price.title")
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              class="my-0"
              color="white"
              height="1"
              :indeterminate="loading"
            ></v-progress-linear>
            <v-divider light></v-divider>
            <v-card-actions>
              <span class="text-body-2">{{
                $t("statistics.messages.price.description")
              }}</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    summary: {
      type: Object,
      default: function () {
        return {
          summary: {
            messages_sent: 0,
            messages_received: 0,
            message_sent_price: 0
          },
        };
      },
    },
  },
  data: () => ({}),
};
</script>